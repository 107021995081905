import { SET_CONFIG, SET_NEWSLIST, SET_VIP_ACTIVE, SET_VIP, SET_CREDIT_TOTAL, SET_BALANCE, REQUEST_PROFILE, STATUS_SUCCESS, SET_MOBILEPHONE, SET_RESETPROFILE, SET_PROMOTIONSLIST } from '@/constants'
import { localStorageService } from '@/utils'
import { getListNewsService, getBalanceService, getListPromotionsService, reqConfig } from '@/services'
import * as numeral from 'numeral'

const userModule = {
  state: {
    username: localStorageService.getUsername(),
    password: localStorageService.getPassword(),
    balance: numeral('0'),
    credit_total: numeral('0'),
    vip: 0,
    isVipActive: false,
    mobilePhone: '',
    profileID: '',
    profileName: '',
    usernameList: '',
    news: [
      {
        name: 'ประกาศ เว็บหวยออนไลน์เต็งหนึ่ง',
        date: '16 ก.ค. 2564',
        detail: 'Processing ...'
      },
      {
        name: 'ระบบแนะนำเพื่อน 8%',
        date: '28 พ.ย. 2563',
        detail: 'Processing ...'
      }
    ],
    agentNews: {
      link: '/',
      new: '-',
      banner: require('@/assets/images/banner2.jpg')
    },
    line: process.env.VUE_APP_LINE_URL,
    min_withdraw: 500,
    config: {},
    img_logo1: 'logo-fifalotto-white4' + process.env.VUE_APP_EXT_NAME + '.png',
    img_logo2: 'logo-fifalotto-white5' + process.env.VUE_APP_EXT_NAME + '.png',
    agentPromotions: [],
    depositMode: 0,
    user_agent_id: '',
    pyn_agent_id_special: [5286, 41462],
    permisstion_register: true,
    permisstion_deposit: true,
    permisstion_withdraw: true,
    is_check_bank_name: 1
  },
  getters: {
    getUsername: (state) => state.username,
    getPassword: (state) => state.password,
    getBalance: (state) => numeral(state.balance).format('0,0.00'),
    getCreditTotal: (state) => numeral(state.credit_total).format('0.00'),
    getVip: (state) => state.vip,
    getIsVip: (state) => state.isVipActive,
    getNews: (state) => state.news,
    getAgentNews: (state) => state.agentNews,
    getAgentPromotions: (state) => state.agentPromotions,
    getAgentPromotionsRegister: (state) => state.config.promotion_regsiter ?? [],
    getAgentPromotionsDeposit: (state) => state.config.promotion_deposit ?? [],
    isEnableCheckBankName: (state) => state.config.is_check_bank_name ?? 1,
    getConfig: (state) => state.config,
    getLogo1: (state) => state.img_logo1,
    getLogo2: (state) => state.img_logo2,
    getUsetAgentId: (state) => state.user_agent_id,
    getLine: (state) => state.line,
    getMinWithdraw: (state) => state.min_withdraw,
    getDepositMode: (state) => state.depositMode,
    getPermisstionRegister: (state) => state.permisstion_register,
    getPermisstionDeposit: (state) => state.permisstion_deposit,
    getPermisstionWithdraw: (state) => state.permisstion_withdraw
  },
  mutations: {
    [SET_CONFIG]: (state, config) => {
      if (process.env.VUE_APP_AG_USER !== 'ag_giant') {
        state.line = config.line
      }
      state.min_withdraw = config.min_withdraw
      state.config = config
      if (config.bank_deposit_mode < 2) {
        if (process.env.VUE_APP_DEPOSIT_MODE) {
          state.depositMode = process.env.VUE_APP_DEPOSIT_MODE
        } else {
          state.depositMode = config.bank_deposit_mode
        }
      }
      if (config.a) {
        if (state.pyn_agent_id_special.includes(Number(config.a))) {
          state.user_agent_id = config.a
        }
      } else {
        state.user_agent_id = ''
      }

      if (process.env.VUE_APP_AGENT_BASE === 'PYN') {
        if (Number(state.user_agent_id) === 5286) { // agent_01 => 5286
          state.img_logo1 = 'logo-fifalotto-white4-lion.png'
          state.img_logo2 = 'logo-fifalotto-white5-lion.png'
          state.line = 'https://line.me/R/ti/p/@952weebc'
        } else if (Number(state.user_agent_id) === 41462) { // agent_02b => 41462
          state.img_logo1 = 'logo-fifalotto-white4-camel.png'
          state.img_logo2 = 'logo-fifalotto-white5-camel.png'
          state.line = 'https://lin.ee/6T4gRb9'
        } else {
          state.img_logo1 = 'logo-fifalotto-white4' + process.env.VUE_APP_EXT_NAME + '.png'
          state.img_logo2 = 'logo-fifalotto-white5' + process.env.VUE_APP_EXT_NAME + '.png'
        }
      } else {
        state.img_logo1 = 'logo-fifalotto-white4' + process.env.VUE_APP_EXT_NAME + '.png'
        state.img_logo2 = 'logo-fifalotto-white5' + process.env.VUE_APP_EXT_NAME + '.png'
      }
      // console.log('config')
      // console.log(config.permission.register)
      // state.permisstion_register = config.permission.register
      // state.permisstion_deposit = config.permission.deposit
      // state.permisstion_withdraw = config.permission.withdraw
    },
    [SET_BALANCE]: (state, balance) => { state.balance = numeral(balance) },
    [SET_CREDIT_TOTAL]: (state, credit_total) => { state.credit_total = numeral(credit_total) },
    [SET_VIP]: (state, vip) => { state.vip = Number(vip) },
    [SET_VIP_ACTIVE]: (state, isVipActive) => { state.isVipActive = Number(isVipActive) },
    [REQUEST_PROFILE]: (state) => {
      state.username = localStorageService.getUsername()
    },
    [SET_MOBILEPHONE]: (state, payload) => { state.mobilePhone = payload },
    [SET_PROMOTIONSLIST]: (state, payload) => { state.agentPromotions = payload },
    [SET_RESETPROFILE]: (state, payload) => {
      state.profileID = payload
      state.profileName = payload.profileName
      state.usernameList = payload
    },
    [SET_NEWSLIST]: (state, payload) => {
      state.news = payload.user
      if (payload.agent) {
        state.agentNews = payload.agent
      }
    }
  },
  actions: {
    requestUsername: ({ commit }) => {
      commit(REQUEST_PROFILE)
    },
    getBalanceInfo: async ({ commit }) => {
      try {
        const resp = await getBalanceService()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_BALANCE, resp.data.currentBalance)
          commit(SET_CREDIT_TOTAL, resp.data.currentCreditTotal)
          commit(SET_VIP, resp.data.currentVip)
          commit(SET_VIP_ACTIVE, resp.data.isVipActive)
        } else {
          commit(SET_BALANCE, '0')
        }
      } catch (error) {

      }
    },
    setMobilePhone: ({ commit }, payload) => {
      commit(SET_MOBILEPHONE, payload)
    },
    setProfileReset: ({ commit }, payload) => {
      // console.log('setProfileReset : ' + payload[0].profileID)
      commit(SET_RESETPROFILE, payload)
    },
    setNewsList: ({ commit }, payload) => {
      commit(SET_NEWSLIST, payload)
    },
    getNewsList: async ({ commit }) => {
      try {
        const resp = await getListNewsService()
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_CONFIG, resp.data)
        }
      } catch (error) {
      }
    },
    reqConfig: async ({ commit, state },
      { id, afKey, agKey }) => {
    // reqConfig: async ({ commit }) => {
      try {
        const resp = await reqConfig(id, afKey, agKey)
        if (resp.status_code === STATUS_SUCCESS) {
          // console.log(resp.data)
          commit(SET_CONFIG, resp.data)
        }
      } catch (error) {
      }
    },
    getPromotionsList: async ({ commit }) => {
      try {
        const resp = await getListPromotionsService(process.env.VUE_APP_AG_ID)
        if (resp.status_code === STATUS_SUCCESS) {
          commit(SET_PROMOTIONSLIST, resp.data)
        }
      } catch (error) {
      }
    }
  }
}

export {
  userModule
}
